import KinectFooterComponent from "./kinectfooter"

export default function KinectQuality(){



    return(
        <div className="main">
        <div className="col-lg-12 text-center bg-black text-white pt-3 pb-3">
            <h1 className="fw-bold">Quality Policy</h1>

        </div>

        <section>
            <div  className="container">
                <div className="row pt-4">
                    <div className="col-lg-6  pt-4">
                        <p>Kinect provides Quality Assurance and quality control management for Battery and PowerBank under ISO 9001:2015. The company has developed expertise and it's aim is to achieve a high standard of manufacturing and service to all of our customers.

                            With our quality control team, we are committed to manufacture quality products for unmatchable customer satisfaction and with their quality guidelines. To achieve customer's quality concerns, we have well trained and experienced staff with latest technology.
                            The concept of Total Quality Management can be found right in its name: The word “total” implies that all employees in the organization, from development to production to fulfillment, are obligated to improve operations.

                            The Directors, Management and Staff members are comitted for Quality Control through the Quality Management System, in co-ordination of suppliers and sub contractors. Kinect is also committed to achieving customer satisfaction by the use of quality procedures of ISO 9001:2015</p>

                    </div>
                    <div className="col-lg-6 pb-4">
                        <img src="images/quality1.jpg" className="img-responsive img-rounded img-fluid" alt="qualitylogo"></img>
                    </div>

                </div>

            </div>

        </section>

        {/* Images */}

        <div>
            <div className="row">
                <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="card">
                        <img src="images/pic1.jpg" width="100%" alt="pic1"></img>
                    </div>

                </div>
                <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="card">
                        <img src="images/pic2.jpg" alt="pic2"></img>
                    </div>

                </div>
                <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="card">
                        <img src="images/pic3.jpg" alt="pic3"></img>
                    </div>

                </div>
                <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="card">
                        <img src="images/pic5.jpg" alt="pic5"></img>
                    </div>

                </div>


            </div>
        </div>

        <div>
            <KinectFooterComponent></KinectFooterComponent>
        </div>
    </div>
    )


}