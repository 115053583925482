import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import KinectAboutUs from "./kinect-AboutUs";
import KinectClients from "./kinect-Clients";
import KinectContactUs from "./kinect-ContactUs";
import KinectManufacturing from "./kinect-manufacturing";
import KinectQuality from "./kinect-quality";
import KinectHome from "./kinectHome";
import "./kinect.css";
import KinectInnovation from "./kinect-Innovation";
import KinectFuture from "./kinect-Future";
import KinectPolicy from "./kinect-policy";
import ReactDropdown from "react-dropdown";
import 'react-dropdown/style.css';
import $ from 'jquery';




export default function KinectIndex() {



    return (

        <div className="main">
            <Router>




                <div className="main1 bg-primary text-white pt-1 pb-1">
                    <div className="row">

                        <div className="col-md-3 col-xs-12 text-lg-left ps-4 pt-3">
                            <Link to="home"><img src="images/company-logo1.jpg" className="img-fluid"></img></Link>
                        </div>

                        <div className="col-md-7 col-xs-12 text-lg-left text-white pt-4" style={{ marginTop: "8px", fontSize: "17px", fontWeight: "bold" }}>
                            <ul className="list-inline me-2">
                                <li className="list-inline-item pe-2">
                                    <Link id="hhhh" className="text-white text-decoration-none" to="aboutus">About Us</Link>
                                </li>

                                <li className="list-inline-item pe-2">
                                    <Link className="text-white text-decoration-none" to="manufacturing">Manufacturing</Link>
                                </li>



                                <li className="list-inline-item pe-2">
                                    <Link className="text-white text-decoration-none" to="quality">Quality</Link>
                                </li>



                                <li className="list-inline-item pe-2">
                                    <Link className="text-white text-decoration-none" to="clients">Clients</Link>
                                </li>
                                <li className="list-inline-item pe-2">

                                    {/* <Link className="text-white text-decoration-none" to="policy">Policy</Link> */}

                                    <div class="dropdown">
                                        <a class="btn btn-primary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                           <b className="fw-bold"> Our Poilcies</b>
                                        </a>

                                        <ul class="dropdown-menu">
                                            <li><Link to="policy" class="dropdown-item">E-Waste Policy</Link></li>
                                            <li><Link to="quality" class="dropdown-item">Quality Policy</Link></li>
                                            
                                        </ul>
                                    </div>


                                </li>
                                <li className="list-inline-item pe-2">
                                    <Link className="text-white text-decoration-none" to="contactus">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-2 col-xs-12 text-lg-right mt-3">
                            <span id="social1" className="bi bi-facebook"></span>
                            <span id="social1" className="bi bi-linkedin"></span>
                            <span id="social1" className="bi bi-twitter"></span>
                        </div>

                    </div>
                </div>




                <section>
                    <Routes>

                        <Route path="/home" exact element={<KinectHome />} >Home</Route>
                        <Route path="/" exact element={<KinectHome />} >Home</Route>
                        <Route path="/aboutus" exact element={<KinectAboutUs />}></Route>
                        <Route path="/clients" exact element={<KinectClients />}></Route>
                        <Route path="/manufacturing" exact element={<KinectManufacturing />}></Route>
                        <Route path="/contactus" exact element={<KinectContactUs />}></Route>
                        <Route path="/quality" exact element={<KinectQuality />}></Route>
                        <Route path="/innovation" exact element={<KinectInnovation />}></Route>
                        <Route path="/future" exact element={<KinectFuture />}></Route>
                        <Route path="/policy" exact element={<KinectPolicy />}></Route>
                        <Route path="*" exact element={<KinectHome />}></Route>



                    </Routes>
                </section>

            </Router>

            <script src=""></script>
        </div>



    )


}