import KinectFooterComponent from "./kinectfooter";
import { BrowserRouter as Router , Link } from "react-router-dom";


export default function KinectClients() {



    return (
        <div>
            <div className="bg-black text-center text-white pt-3 pb-3">
                <h1 className="fw-bold">Our Clients</h1>
            </div>


            <div className="container p-4">
                <p><b>Kinect Power Private Limited</b> works with leading brands like <b>Nokia,Transsion,Karbonn,Jio,Itel,Oraimo</b> etc.

                    Kinect Power Private Ltd is one of the leading Global EMS companies with strong partnership record of servicing customer in India & Globally. Founded in 2015 at Noida, Uttar Pradesh under the "Make in India" initiative by Government of India. As a Global EMS Manufacturing company, we at Kinect focused on Flexible Manufacturing Process, Robust IT Infrastructure and On Time Delivery for our Customer.
                    Kinect Quality Policy of maximize customer needs and competitive products and services delivered to customers on time. </p>
            </div>


            <div className="container pb-lg-4 ">
               <marquee behavior="scroll" scrollamount="7" direction="left">
               <img src="images/transsion.jpg" className="img-fluid pe-5"></img>
               <img src="images/nokia.jpg" className="img-fluid pe-5"></img>
               <img src="images/itel.png" className="img-fluid pe-5"></img>
               <img src="images/jio.jpg" className="img-fluid pe-5"></img>
               <img src="images/karbonn.jpg" className="img-fluid pe-5"></img>
               <img src="images/oraimo.png" className="img-fluid pe-5"></img>

               </marquee>
            </div>


                   {/* Images */}


            <div>
                <div className="row">
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic1.jpg" width="100%" alt="pic1"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic2.jpg" alt="pic2"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic3.jpg" alt="pic3"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic5.jpg" alt="pic5"></img>
                        </div>

                    </div>


                </div>
            </div>

            <KinectFooterComponent></KinectFooterComponent>

        </div>





    )
}



