import KinectFooterComponent from "./kinectfooter";
import { BrowserRouter as Router , Link } from "react-router-dom";


export default function KinectContactUs(){



    return(
        <div>
            <div className="bg-black text-white  text-center pt-3 pb-3">
                <h1 className="fw-bold">Contact Us</h1>

            </div>

            <div className="container">
                <div className="row pt-5 pb-4">
                    <div className="col-md-6 bg-black text-white text-center">
                        <br/>
                        <h2 className="pt-4">WE LOVE TALKING TO YOU.</h2>
                        <br/>
                        <p className="pt-3"><span style={{color:"#bcb9b9"}}> <b>You can ring us at :</b> </span> +91-8920916782,+91-9582669538</p>
                        <p><span style={{color:"#bcb9b9"}}> <b>Drop us a note at :</b> </span>kinect.akta@gmail.com , adf_cp004@163.com</p>

                        <hr/>
                        <br/>
                        
                        
                        
                        <p className="pt-3"><span style={{color:"#bcb9b9"}}><b>Office Address :</b></span> C-44 2nd Floor,<br/>Sector-57,Noida,<br/>UP-201301, India</p>
                        
                        
                    </div>
                    <div className="col-lg-6">

                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.7658551773534!2d77.34881751440662!3d28.60680029199735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5187d2b8989%3A0xbb11ecb7fd133820!2sKINECT%20POWER%20LTD!5e0!3m2!1sen!2sin!4v1672649217790!5m2!1sen!2sin" width="600" height="450" style={{border:"0"}} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                    </div>

                </div>


            </div>

            <br/>
            <br/>
            <br/>
            <br/>
            
            <KinectFooterComponent></KinectFooterComponent>
        </div>
    )


}

