import KinectFooterComponent from "./kinectfooter";
import { BrowserRouter as Router , Link } from "react-router-dom";




export default function KinectManufacturing() {



    return (
        <div>
            <div>
                <div className="col-lg-12 bg-black text-white pt-3 pb-3  text-center">
                    <h1 className="fw-bold">Power Storage Device Manufacturing</h1>

                </div>
                <div className="col-lg-12 p-4">
                    <p>The Indian KINECT POWER PRIVATE LIMITED company was established at the end of 2015 and is affiliated to Hunan ADF Company. The main business is a private company integrating the design, development, production, manufacturing and sales of mobile phone batteries and mobile power supplies. The factory area is now 18,000 square meters, with 6 production lines, four mobile phone battery manufacturing lines, and two mobile power supply manufacturing lines, with a production capacity of 60,000 mobile phone batteries only/day, power bank 4K/day, existing customers: Nokia, jio, ITEL, lava, Karbonn, Benco and other brands, and obtained the ISO9001-2015 quality system certification in July 2020, there are currently 18 managers, Chinese management 2 people. Kinect has the right to import battery products CKD. <br /> <br />
                        KINECT POWER PRIVATE LIMITED is One of India’s leading Mobile Phone Batteries and Mobile Power Supplies Manufacturing providers, we have all ESD protected and are equipped with all the necessary repair and testing infrastructure. All of them are also connected by a CRM that has been designed and developed by a dedicated team. <br /> <br />

                        Our team is highly trained & skilled and we always endeavor to provide the best services to our clients.We take pride in having one of the largest Network in the Manufacturing unit in Noida for Mobile Phones Batteries and Mobile Power Supplies. We understand the value of customer satisfaction for a brand.
                    </p>

                </div>

                <div className="ps-4">
                    <h3 className="fw-bold">PRODUCT & SERVICES</h3>
                    <p><span className="bi bi-chevron-right"></span><span className="bi bi-chevron-right"></span> Mobile Phone Batteries</p>
                    <p><span className="bi bi-chevron-right"></span><span className="bi bi-chevron-right"></span>Mobile Phone Power Supplies</p>
                    <p><span className="bi bi-chevron-right"></span><span className="bi bi-chevron-right"></span>Power Bank</p>
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-6"><img src="images/battery.jpg" class="img-fluid rounded mx-auto d-block img-thumbnail" alt="battery"></img></div>
                        <div className="col-6"><img src="images/powerbank.jpg" class="img-fluid rounded mx-auto d-block img-thumbnail" alt="powerbank"></img></div>
                    </div>

                    <div className="p-5">
                        <img src="images/mobile.jpg" class="img-fluid rounded mx-auto d-block img-thumbnail" alt="battery-manufacturing" ></img>
                    </div>


                </div>

            </div>
            {/* Images */}


            <div>
                <div className="row">
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic1.jpg" width="100%" alt="pic1"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic2.jpg" alt="pic2"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic3.jpg" alt="pic3"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic5.jpg" alt="pic5"></img>
                        </div>

                    </div>


                </div>
            </div>

            <KinectFooterComponent></KinectFooterComponent>
        </div>
    )


}

