import { BrowserRouter as Router, Link } from "react-router-dom";
import './kinect.css';



export default function KinectFooterComponent() {


    return (

        // footer 

        <div>
            
                <footer className="site-footer">
                    <div className="bottom">
                        <div className="container">
                            <div className="row">

                                <div className="col-md-5 col-xs-12 text-lg-left">
                                    <p className="copyright-text">
                                        &copy; Kinect Power Private Limited.
                                    </p>

                                </div>

                                <div className="col-md-7 col-xs-12 text-lg-right ">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <Link className="text-decoration-none" to="/home">Home</Link>
                                        </li>
                                        <li className="list-inline-item">
                                            <Link className="text-decoration-none" to="/aboutus">About Us</Link>
                                        </li>

                                        <li className="list-inline-item">
                                            <Link className="text-decoration-none" to="/manufacturing">Manufacturing</Link>
                                        </li>



                                        <li className="list-inline-item">
                                            <Link className="text-decoration-none" to="/quality">Quality</Link>
                                        </li>



                                        <li className="list-inline-item">
                                            <Link className="text-decoration-none" to="/clients">Clients</Link>
                                        </li>
                                        <li className="list-inline-item">
                                            <Link className="text-decoration-none" to="/policy">Policy</Link>
                                        </li>
                                        <li className="list-inline-item">
                                            <Link className="text-decoration-none" to="/contactus">ContactUs</Link>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </footer>
                
           

        </div>
    )
}