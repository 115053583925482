import KinectFooterComponent from "./kinectfooter";
import { BrowserRouter as Router , Link } from "react-router-dom";





export default function KinectFuture() {

    return (
        <div>
            <div className="bg-black text-center text-white pt-3 pb-3">
                <h1 className="fw-bold">Future Plan</h1>
                
            </div>
            <div className="container">
                    <p className="pt-5">As part of the global progress plan and an initiative towards continual improvement, the future plan of this project is to add more production lines in order to increase the capacity and meet the global customer demand.<br />
                    </p>


                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                 {/* Images */}


            <div>
                <div className="row">
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic1.jpg" width="100%" alt="pic1"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic2.jpg" alt="pic2"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic3.jpg" alt="pic3"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic5.jpg" alt="pic5"></img>
                        </div>

                    </div>


                </div>
            </div>
                    <KinectFooterComponent></KinectFooterComponent>
        </div>
    )
}