import KinectFooterComponent from "./kinectfooter";
import { BrowserRouter as Router , Link } from "react-router-dom";





export default function KinectInnovation() {


    return (
        <div>
            <div>
                <div className="bg-black text-white text-center pb-3 pt-3">
                    <h1 className="fw-bold">Innovation & Technology</h1>
                </div>

                <div className="container col-md-12">
                    <p className="pt-4">Technology transfer from leading countries like China, Japan & Europe for the key equipment along with development of localized jigs & fixtures to ease out the processing are the key points under category of innovation & technology.<br/><br/>

                        Apart from this, Participative Innovation programs in form of Kaizen deployed in plant so that each person could participate in idea submission and transformation of the same into reality using industrial engineering techniques.<br/><br/>

                        In order to support the Skill India mission of Government of India, people from almost all areas of country were selected and deployed in the manufacturing process. The knowledge share process was centrally commanded by the head of unit with effective feedback & evaluation mechanism.<br/><br/>

                        Social networking & Internet were the key elements in deployment and dissemination of the information.<br/><br/>

                        Confidence was created among the customer using standard benchmarking processes for all departments of location and following the international level of Quality standards.<br/><br/>

                        With the setup of local unit of manufacturing, Kinect have successfully obtained the user requirement of best quality product at a low cost. This contributes towards connecting India with new technology.<br/><br/>

                        Expansion plan for the mobile phone batteries and power storage devices manufacturing along with the new paths towards creation of more jobs, new technology and will keep Skill India project empowering with the advance of time.</p><br/>

                </div>

            </div>

             {/* Images */}


             <div>
                <div className="row">
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic1.jpg" width="100%" alt="pic1"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic2.jpg" alt="pic2"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic3.jpg" alt="pic3"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic5.jpg" alt="pic5"></img>
                        </div>

                    </div>


                </div>
            </div>


                <KinectFooterComponent></KinectFooterComponent>


        </div>
    )
}