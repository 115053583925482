import KinectFooterComponent from "./kinectfooter";
import { BrowserRouter as Router , Link } from "react-router-dom";


export default function KinectAboutUs() {



    return (

        <div>
            <div className="col-lg-12 text-center bg-black text-white pt-3 pb-3">
                <h1 className="fw-bold">About Us</h1>

            </div>
            <div className="container p-4">
                <p>The Indian <b>KINECT POWER PRIVATE LIMITED</b> company was established at the end of 2015 and is affiliated to Hunan ADF Company. The main business is a private company integrating the design, development, production, manufacturing and sales of mobile phone batteries and mobile power supplies. The factory area is now 18,000 square meters, with 6 production lines, four mobile phone battery manufacturing lines, and two mobile power supply manufacturing lines, with a production capacity of 60,000 mobile phone batteries only/day, power bank 4K/day, existing customers: Nokia, jio, ITEL, lava, Karbonn, Benco and other brands, and obtained the ISO 9001-2015 quality system certification in July 2020, there are currently 18 Managers, Chinese Management 2 people. Kinect has the right to import battery products CKD. <br />
                    Kinect Power Private Ltd is one of the leading Global EMS companies with strong partnership record of servicing customer in India & Globally. Founded in 2015 at Noida, Uttar Pradesh under the "Make in India" initiative by Government of India. As a Global EMS Manufacturing company, we at Kinect focused on Flexible Manufacturing Process, Robust IT Infrastructure and On Time Delivery for our Customer. <br />
                    Kinect Quality Policy of maximize customer needs and competitive products and services delivered to customers on time.
                </p>
            </div>


            <div className="container p-4">
                <div className="row">
                    <div className="col-lg-3">
                        <h3 className="bg-dark text-white text-center">Mission:</h3>
                        <p>Mission to implement India’s clean energy revolution and to innovative power solutions.
                        Provide high quality and cost effective mobile phone batteries for consumers.
                        </p>

                    </div>
                    <div className="col-lg-3">
                        <h3 className="bg-dark text-white text-center">Vision:</h3>
                        <p>Vision to create India symbol in the new energy sector.To create a healthy ecological chain of mobile terminal to increase connectivity among the people.</p>
                    </div>
                    <div className="col-lg-3">
                        <h3 className="bg-dark text-white text-center">Core Values:</h3>
                        <p>Core Values to customer success, integrity and responsibility, learning and innovation!</p>
                    </div>
                    <div className="col-lg-3">
                        <h3 className="bg-dark text-white text-center">Company Culture:</h3>
                        <p>Company Culture for "loyalty" culture -- integrity, broad mind-- Do what you say, and do what you do.
</p>
                    </div>
                </div>

            </div>


               {/* Images */}


               <div>
                <div className="row">
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic1.jpg" width="100%" alt="pic1"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic2.jpg" alt="pic2"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic3.jpg" alt="pic3"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic5.jpg" alt="pic5"></img>
                        </div>

                    </div>


                </div>
            </div>

            <KinectFooterComponent></KinectFooterComponent>


        </div>
    )
}