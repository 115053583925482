import "./kinect.css"
import KinectFooterComponent from "./kinectfooter"
import { Link} from "react-router-dom"


export default function KinectHome() {



    return (
        <main>
            <div style={{ height: "600px", backgroundColor: "rgb(20, 20, 20)" }}>

                <div className="d-flex justify-content-center align-items-center" style={{ height: "600px" }} >
                    <div>
                        <h1 className="text-center mt-4" style={{ fontSize: "50px", color: "white", fontFamily: "Times New Roman" }}> KINECT POWER PRIVATE LIMITED</h1>




                        <br />
                        <br />
                        <br />
                        <br />
                        <br />

                        <h2 className="text-center text-white">Aligned with the "Make in India" & "Digital India" campaign to boost local manufacturing,
                            the plant is set up with the objective to fulfill the Electronic Devices manufacturing.</h2>
                    </div>
                </div>





            </div>
            {/* Innovation future */}
            <section className="bg-dark text-white pb-4 pt-4">
                <div className="container">
                    <div className="row pe-2 ps-2">
                        <div className="col-lg-6 col-sm-12 text-lg-left text-center mt-4 mb-4">
                            <h2 className="text-center">
                                Innovation & Technology
                            </h2>
                            <p style={{ fontSize: "20px" }}>Technology transfer from leading countries like China, Japan & Europe for the key equipment along with development of...  </p>
                            <Link to="/innovation" className="text-decoration-none fw-bold text-white btn btn-outline-secondary" >Learn more</Link>
                        </div>
                        <div className="col-lg-6 col-sm-12 text-lg-right text-center mt-4 mb-4 ">
                            <h2 className="text-center"> Future Plan</h2>
                            <p style={{ fontSize: "20px" }}>As part of the global progress plan and an initiative towards continual improvement, the future plan of this project is to add more...</p>
                            <Link to="/future" className="text-decoration-none fw-bold text-white btn btn-outline-secondary">Learn more</Link>
                        </div>

                    </div>

                </div>
            </section>

            <div className="mt-2">
                <div className="bg-secondary w-100 pt-2 pb-2">
                    <h1 className="text-center text-white fw-bold">CORE CORPORATE VALUE</h1>

                </div>

            </div>
            {/* pioneer */}

            <div className="pt-4 pb-4 bg-dark text-white">
                <div className="row pb-4 pt-4 ps-4 pe-4">
                    <div className="feature-col col-lg-4 col-xs-12 text-lg-left text-center">
                        <div>
                            <div className="feature-icon">
                                <span className="bi bi-box-arrow-in-up-right"></span>

                            </div>
                        </div>
                        <div>
                            <h3>Innovative</h3>
                            <p> Kinect keep up with the
                                industrial trend by innovating products, technology management and
                                operation...< br /><Link to="/aboutus">learn more &raquo;</Link></p>
                        </div>

                    </div>
                    <div className="feature-col col-lg-4 col-xs-12 text-lg-center text-center">
                        <div>
                            <div className="feature-icon">
                                <span className="bi bi-laptop"></span>

                            </div>
                        </div>
                        <div>
                            <h3>Pioneering</h3>
                            <p> We are full of ventures' pioneering spirit, keeping exploiting new markets and products...< br /><Link to="/aboutus">learn more &raquo;</Link></p>
                        </div>



                    </div>


                    <div className="feature-col col-lg-4 col-xs-12 text-lg-right text-center">
                        <div>
                            <div className="feature-icon">
                                <span className="bi bi-cpu-fill"></span>

                            </div>
                        </div>
                        <div>
                            <h3>Excellent</h3>
                            <p> Pursuing excellent quality, training excellent talents and building excellent corporation...< br /><Link to="/aboutus">learn more &raquo;</Link></p>
                        </div>


                    </div>

                </div>
            </div>


            {/* Images */}


            <div>
                <div className="row">
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic1.jpg" width="100%" alt="pic1"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic2.jpg" alt="pic2"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic3.jpg" alt="pic3"></img>
                        </div>

                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="card">
                            <img src="images/pic5.jpg" alt="pic5"></img>
                        </div>

                    </div>


                </div>
            </div>

            <KinectFooterComponent></KinectFooterComponent>










        </main>
    )
}