import KinectFooterComponent from "./kinectfooter";
import { BrowserRouter as Router , Link } from "react-router-dom";






export default function KinectPolicy() {


    return (
        <div>
            <div>

                <div className="bg-black pt-3 pb-3 text-white text-center">
                    <h1 className="fw-bold">E-Waste Policy</h1>

                </div>

                <div className="container">
                <h3 className="pt-4">KINECT POWER PRIVATE LIMITED.</h3>
                <h4>E-waste program.</h4>
                <p>At KINECT POWER PRIVATE LIMITED., The PRO is implementing a sustainable, cost-effective, and environmentally sound collection and recycling mechanism that is consistent with the industry objectives and in compliance with the E-waste (Management) Rules 2016 we believe that it is our responsibility to ensure that we do business in such a manner that it enriches our environment and planet. That’s why we make sure that we deliver innovative and Eco-friendly products to our consumers. We are committed to product stewardship through the life cycle of our electronics, and ensure compliance with E-waste rules in India.</p>

                <h4>About E-waste</h4>
                <p>E-waste has been defined as “waste electrical and electronic equipment, whole/in part or rejects from their manufacturing and repair process, which are intended to be discarded.” In other words, E-waste or Waste Electrical and Electronic Equipment is the term used to describe old, end-of-life or discarded appliances using electricity. With the increase in usage and dependence on electrical and electronic gadgets such as washing machines, refrigerators, air conditioners etc. there has been increase in E-waste generation in large quantities. A UN report estimates that the world wide generation of E-waste is around 30 to 50 Metric tone per annum. E-waste generally consists of those substances which are hazardous for environment and can seriously affect the health of the human beings.</p>
                <h3>Benefits of recycling of old electronic products/why recycle?</h3>
                <h5>1. Saves Natural Resources:</h5>
                <p>There is a need to encourage recycling of all useful and valuable material from E-waste so as to conserve the ever depleting natural resources. Recycling end-of-life products is vital if we are to save resources and minimize waste.</p>

                <h5>2. Reduces Environmental Pollution:</h5>
                <p>Scientific disposal of E-waste reduces the environment pollution. Moreover, making products from recycled materials creates less air and water pollution than making products from virgin materials.</p>

                <h5>3. Alternate Resource:</h5>
                <p>With virgin resources facing rapid depletion, E-waste can be a good alternate resource since it contains many valuable and recoverable materials such as aluminum, copper, gold, silver and ferrous materials.</p>

                <h3>Negative consequences of disposal of product in an unscientific manner</h3>

                <h5>1. Health Risks:</h5>
                <p>E-waste-connected health risks may result from direct contact with harmful materials such as lead, cadmium, chromium, brominated flame retardants or polychlorinated biphenyls (PCBs), from inhalation of toxic fumes, as well as from accumulation of chemicals in soil, water and food.</p>

                <h5>2. Children Are Vulnerable:</h5>
                <p>Children require more specific protection from E-waste exposure. As they are still growing, their air, water and food intake is more as compared to adults- and with that the risk of hazardous chemical absorption. Moreover, it may cause an irreversible damage to their central nervous system, immune system, reproductive and digestive system.</p>

                <h5>3. Environmental Degradation:</h5>
                <p>Oil and gases which are present in E-waste such as compressor and CRT causes environmental pollution. Burning of rubber and plastic in open area causes air pollution.</p>

                <h5>Accidental breakage/damage of electronics & E-waste :</h5>
                <p>Accidental breakage and damage of electronics and E-waste can pose a threat to the environment. As long as the toxic components in electronics are enclosed within the original manufacturer’s designed outer-shell of the gadget, they don’t pose much of a danger as they have been engineered to contain any such effects. However, accidental breakage or damage of such electronics or E-waste can lead to toxic elements leaking and contaminating their immediate environment. Damage to the environment due to accidental breakage of E-waste is a major concern in informal E-waste disposal and recycling facilities, so consumers need to ensure that they dispose their E-waste only at authorized E-waste Recycler.</p>

                <h5>Brief about E-waste (Management) rules 2016</h5>
                <p>The E-waste (Management) Rules, 2016 have been notified with primary objective to channelize the E-waste generated in the country for environmentally sound recycling which is largely controlled by the UN-organized sector who are adopting crude practices that results into higher pollution and less recovery, thereby causing wast ages of precious resources and damage to environment.</p>

                <h3>Guidelines for customers on the disposal of end-of-life products</h3>
                <h4>Collection Methods</h4>
                <h5>Own Collection Center </h5>
                <p>As required by the regulatory bodies, the Ewour Waste Management Pvt Ltd. shall establish collection centers to drive own collection. Own collection centers will be one source of achieving the required overall volumes. Collection centers will be either fully owned by the Ewour PRO or will be outsourced to PRO partners. Collection centers will be secured with metal detectors, CCTV cameras and equipped with collection containers. There will be a registration desk where a collector accepts and registers the equipment into the IT system. All staff will wear safety equipment including helmets, safety shoes, gloves, goggles. The collection and operations staff will be trained and the collection centers will adhere to Environmental Health and Safety Management Standards (EHSMS) standards. Each collection centers will have its own management team, recruited collectors, security and helpers to store and manage the collected e-waste. Collectors will own vehicles, bikes and tricycles to support collection. Collection centers will aim at integrating low skilled labor into the formal e-waste collection. The PRO will cooperate with experts in the area to create the most effective incentives to integrate the informal channel into the overall collection process</p>

                <h5>Bulk customer Pickup</h5>
                <p>The Ewour Waste Management Pvt Ltd. will operate a system for managing pickups from Bulky customers as defined in E-waste (Management) Rules 2016. The PRO will approach bulky customers proactively in order to encourage them to shift their used products into the PRO network. It is assumed; initially the PRO will have to purchase this material. There will be a web form and a toll-free number set up to facilitate the management of the collection pickup requests and once confirmed, the PRO will dispatch the pickup request to the optimal service provider (depending on their capabilities, coverage, quality and price) to be auctioned.
                </p>

                <h5>How to drop off products at our collection point?</h5>
                <p>Here are the steps you need to follow when dropping off your electronics item at our Collection Point:</p>
                <p>1. Pack the electronics item in light packaging, so that the device is secured inside safely.<br/>
                    2. Identify the Collection Point in your state through customer care.<br/>
                    3. Take the packaged product to the Collection point.<br/>
                    4. At the Collection Point, you will be provided with a form. Please fill in the necessary details.<br/>
                    5. Now submit the product package along with the filled up form at the Collection Point.
                    This way, the appliance recycling organized by Ewour Waste Management Pvt Ltd. will be done under best possible conditions, in compliance with E-waste Management and Handling Rules on Waste Electrical and Electronic Equipment. We thank you for fulfilling your responsibility towards environment.</p>

                <p>Ewour Waste Management Pvt Ltd. As part of this partnership with Ewour Waste Management Pvt Ltd.  the company will ensure environmentally sound management of electronics that have reached their end of life phase. Ewour Waste Management Pvt Ltd. has obtained all the necessary authorizations from the appropriate governmental agencies for their processing facilities. Ewour Waste Management Pvt Ltd. ensures proper recycling and disposal of e-waste. This helps us protect the environment from any hazardous consequences, which would be otherwise caused by the inappropriate waste management of e-waste.</p>

                <h4>Do’s and don’ts for customers</h4>
                <h5>Do’s</h5>
                <p>1. Always look for information on the Catalog with your product for end-of-life equipment handling.<br/>
                    2. Ensure that only Ewour repair and handle your electronic products.<br/>
                    3. Always call Local Ewour PRO to dispose products that have reached end-of life.<br/>
                    4. Always drop your used electronic products, batteries or any accessories when they reach the end of their life at your nearest Ewour  PRO Call Center.<br/>
                    5. Wherever possible or as instructed, separate the packaging material according to responsible waste disposal options and sorting for recycling.<br/>
                    6. Always disconnect the battery from product, and ensure any glass surface is protected against breakage.</p>

                <h5>Don’ts</h5>
                <p>1. Do not dismantle your electronic products on your own.<br/>
                    2. Do not throw electronics in bins having “Do Not Dispose” sign.<br/>
                    3. Do not give E-waste to informal and unorganized sectors like Local Scrap Dealer/ Rag Pickers.<br/>
                    4. Do not dispose your product in garbage bins along with municipal waste that ultimately reaches landfills.</p>

                <h4>Collection Points/Drop Points</h4>
                <h5>FAQ's</h5>

                <p>• Do consumers have to pay for getting their electronics recycled?
                    Consumers do not have to pay any fee for recycling of KINECT POWER PRIVATE LIMITED. Product/used in India.<br/>
                    • Is there any monetary benefit/discount linked to this take-back and recycling program?
                    As of now there is no monetary benefit/discount linked to this program, however one can check for latest discounts/offers for exchange at your nearest KINECT POWER PRIVATE LIMITED.,  Branch. The main benefit of this program is cleaner, safer and greener environment.<br/>
                    • Which KINECT POWER PRIVATE LIMITED. Product can be recycled through this initiative?
                    Currently the initiative covers pick up of e-waste related to only Hard Drives, Electric and Electronic Equipment.<br/>
                    • Do consumers have to pay to get their KINECT POWER PRIVATE LIMITED. Product disposed?
                    Consumers do not have to pay for safe disposal of their appliances through KINECT POWER PRIVATE LIMITED., . Product. Authorized PRO .<br/>
                    • Can we dispose any old product through KINECT POWER PRIVATE LIMITED. Product. authorized PRO recyclers /agents?
                    No. You can dispose only KINECT POWER PRIVATE LIMITED.,  Product through our PRO recyclers/agents.<br/>
                    • Is it possible to dispose of the products from anywhere in India, and how much time will it take?
                    Yes it is possible and it will take about 5- 10 days to get the products picked by our authorized PRO from the time of the first call made to call center.<br/>

                    In case of any queries regarding the proper disposal and /or recycling of electronics, consumers can contact the helpdesk (Toll free) at:

                </p>
                <div className="d-flex justify-content-between pb-4"><span className="bi bi-telephone-fill">Toll Free No: 1800-889-0452</span> <span className="bi bi-envelope-at-fill">Email: info@ewour.in</span> </div>

                </div>

                    


            </div>

            <KinectFooterComponent></KinectFooterComponent>


        </div>
    )
}